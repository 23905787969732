import React from "react"
import { graphql } from "gatsby"
import MDXRoot from "gatsby-plugin-mdx/wrap-root-element"
import { MDXRenderer } from "gatsby-plugin-mdx"

const PrivacyPolicyPage = props => {
  return (
    <div style={{ maxWidth: 1024, margin: `24px auto`, padding: "64px" }}>
      <MDXRoot>
        <MDXRenderer>{props.data.mdx.body}</MDXRenderer>
      </MDXRoot>
    </div>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      body
    }
  }
`

export default PrivacyPolicyPage
